import React, { useEffect, useState } from 'react';
import CartIcon from '../../icons/cart';
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { cartState } from '../../atoms';
import { updateCartState } from '../../utils';


export default function BookPage() {
  const [cart, setCart] = useRecoilState(cartState);

  document.title = `Grocery List by Bora Chung, translated by Anton Hur | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `In <em>Grocery List</em>, the Korean writer Bora Chung reimagines the ghost story as a chilling tale of intimacy with appetite.`);

  const addToCart = () => {
    let newCart = updateCartState(cart, {
      id: '9798990416529',
      title: 'Grocery List',
      author: 'Bora Chung',
      image: 'GroceryList.png',
      priceId: 'price_1PTBM1FoScMjUSrmDaiSQ6l4',
      price: 14.99
    });
    setCart(newCart);
  }

  return (<div className='pageContainer n2-book'>
    <div className='n-book-images'>
      <div className='n-image n-primary-image'><img src='../../GroceryList.png' /></div>
      <div className='n-image n-image-spine'><img src='../../boraspine.png' /></div>
      <div className='n-image n-image-back'><img src='../../boraback.png' /></div>
      <div className='n-image n-image-2'><img src='../../bora3.png' /></div>
      <div className='n-image n-image-3'><img src='../../bora2.png' /></div>
    </div>

    <div className='n2-book-data'>
      <div className='n2-book-series'><Link to='/series/two'>Series Two</Link> | Spring 2024</div>
      <h1 className='n2-book-title'>Grocery List</h1>
      <div className='n2-book-author'>Bora Chung, trans. Anton Hur</div>

      <div className='n2-book-stats'>
        <div>ISBN 979-8-9904165-2-9</div>
        <div style={{ marginBottom: '6px' }}>80pp | $14.99 USD</div>
        {/*<div className='buyingOptions'>
          <button onClick={addToCart} className='cartButton'>Add to Cart </button>
        </div>*/}
        <div style={{ marginTop: '12px' }}>
          <a rel='noreferrer' target='_blank' href='https://asterismbooks.com/product/grocery-list-bora-chung'>Buy from Asterism (US/Canada)</a><br />
          <a rel='noreferrer' target='_blank' href='https://publicknowledgebooks.com/products/bora-chung-trans-anton-hur-grocery-list'>Buy from PKB (UK/EU)</a>
        </div>
      </div>
      <div className='mainText'>

        <p><em>“‘Do you know what an ah-gwi is?’ ‘A ghost of someone who starved to death.’ Ghost stories, urban legends, weird tales floating around the Internet, I was into all of that. Sometimes, I would tell her some of them between puffs as we stood there staring at the dumpsters. But this was the first time she had mentioned that stuff on her own.”</em></p>
        <p>In <em>Grocery List</em>, the Korean writer Bora Chung reimagines the ghost story as a chilling tale of intimacy with appetite. The dividing lines of reality and thoughtforms blur as Chung takes on the ever-timely subject of food consciousness. Cutting and evocative, <em>Grocery List</em> is a feast for eaters of all kinds.</p>
        <p>Born in Seoul, <strong>Bora Chung</strong> is a distinct new voice in global literature. The author of three novels and three collections of short stories, Chung was shortlisted for the International Booker Prize in 2022. Chung holds an MA in Russian and East European area studies from Yale University and a PhD in Slavic literature from Indiana University. She teaches Russian language and literature and science fiction studies at Yonsei University, and translates modern literary works from Russian and Polish into Korean.</p>
        <p><strong>Anton Hur</strong> was born in Stockholm and currently resides in Seoul. He won a PEN Translates grant for his translation of <em>The Underground Village</em> by Kang Kyeong-ae and a PEN/Heim grant for Bora Chung’s <em>Cursed Bunny</em>, the latter of which was shortlisted for the 2022 International Booker Prize; his translation of Sang Young Park’s <em>Love in the Big City</em> was longlisted for the same prize that year. Other internationally recognized translations include Kyung-Sook <em>Shin’s Violets</em> (2022) and Baek Sehee’s <em>I Want to Die but I Want to Eat Tteokbokki</em> (2018). Also a writer, Hur’s novel, <em>Toward Eternity</em>, a speculative treatment of a transhumanist future, will debut with HarperVia in summer 2024.</p>
      </div>
    </div>
  </div>)
}