import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CartIcon from '../../icons/cart';

export default function SeriesTwo() {

  document.title = `Series Two | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `First series includes books by John Ashbery, Cookie Mueller, Eileen Myles, René Daumal, Max Beckmann, and Dodie Bellamy`);


  return (
    <div className='pageContainer booksLanding'>

      <div className='booksPage'>
        <h1 className='gold'>Series Two</h1>
        <p className='seriesGraf'>Gathering approaches from the diffuse planetary avant-garde of today, the second series of Hanuman Editions brings together works by McKenzie Wark, Bora Chung, Enrique Vila-Matas, Vivek Narayanan, and the cult duo of Raymond Pettibon and Mike Topp. We also reissue Cookie Mueller’s <em>Fan Mail, Frank Letters, and Crank Calls</em> from the original Hanuman catalogue.</p>

        <h3 className='seriesSubhead'>Individual Titles</h3>

        <div className='new-grid'>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/life-story-mckenzie-wark'>
                  <img src='../../LifeStory.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div></div>
              <Link to='/books/life-story-mckenzie-wark'>More</Link>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/grocery-list-bora-chung'>
                  <img src='../../GroceryList.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div></div>
              <Link to='/books/grocery-list-bora-chung'>More</Link>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/frontier-index-raymond-pettibon-mike-top'>
                  <img src='../../Frontier.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div></div>
              <Link to='/books/frontier-index-raymond-pettibon-mike-top'>More</Link>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/insistence-as-a-fine-art-enrique-vila-matas'>
                  <img src='../../Insistence.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div></div>
              <Link to='/books/insistence-as-a-fine-art-enrique-vila-matas'>More</Link>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/kuruntokai-vivek-narayanan'>
                  <img src='../../Kuruntokai.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div></div>
              <Link to='/books/kuruntokai-vivek-narayanan'>More</Link>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/fan-mail-frank-letters-crank-calls-cookie-mueller'>
                  <img src='../../FanMail.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div></div>
              <Link to='/books/fan-mail-frank-letters-crank-calls-cookie-mueller'>More</Link>
            </div>
          </div>
        </div>

        <h3 className='seriesSubhead lessMarginBottom' style={{ marginTop: '64px' }}>Limited Edition Box Sets</h3>
        <p className='seriesGraf gold'>Series Two box sets produced in a hand-numbered edition of 500.</p>

        <div className='boxGrid'>
          <div className='boxGrid-item'>
            <img src='../../series2_fm.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Flamingo</div>

              <Link to='https://asterismbooks.com/product/hanuman-editions-series-two-box-set-flamingo'><CartIcon /></Link>
            </div>
          </div>

          <div className='boxGrid-item'>
            <img src='../../series2_nb.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>New Blue</div>

              <Link to='https://asterismbooks.com/product/hanuman-editions-series-two-box-set-new-blue'><CartIcon /></Link>
            </div>
          </div>

          <div className='boxGrid-item'>
            <img src='../../series2_tb.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Tabriz Blue</div>

              <Link to='https://asterismbooks.com/product/hanuman-editions-series-two-box-set-tabriz-blue'><CartIcon /></Link>
            </div>
          </div>


          <div className='boxGrid-item'>
            <img src='../../series2_ct.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Citrine</div>
              <Link to='https://asterismbooks.com/product/hanuman-editions-series-two-box-set-citrine'><CartIcon /></Link>

            </div>
          </div>
        </div>

      </div>
    </div>
  )
}