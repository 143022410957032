import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CartIcon from '../../icons/cart';
import './style.css';

const book = {
  title: 'Garden of Ashes',
  author: 'Cookie Mueller',
  translator: '',
  id: '979–8–989378–02–9',
  pages: 120,
  description: 'Garden of Ashes, Cookie Mueller\'s second entry into the Hanuman Books canon(following 1988\'s Fan Mail, Frank Letters, and Crank Calls) brings together ten stories, autobiographical accounts of her ascent to cult-cinema superstardom, with tales dedicated to several of her fellow Dreamlanders, including "Edith Massey: A Star" and "Divine".',
  bio: 'Cookie Mueller (1949–1989) was an American writer, actress, and advice columnist, best remembered as a regular cast member of some of the director John Waters\'s groundbreaking films, including Multiple Maniacs, Pink Flamingos, Female Trouble, and Desperate Living.She died from AIDS-related complications in 1989.',
  image: '',
  video: '',
  altImageOne: '',
  altImageTwo: ''
}

export default function BookPage() {

  document.title = `Garden of Ashes by Cookie Mueller | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Garden of Ashes, Cookie Mueller's second entry into the Hanuman Books canon brings together ten stories.`);


  return (<div className='pageContainer n2-book'>
    {<div className='n-book-images'>
      <div className='n-image n-primary-image'><img src='../../mueller.png' /></div>
      <div className='n-image n-image-spine'><img src='../../CookieSpine.png' /></div>
      <div className='n-image n-image-back'><img src='../../SeriesOneBack.png' /></div>
      <div className='n-image n-image-2'><img src='../../Cookie2.png' /></div>
      <div className='n-image n-image-3'><img src='../../Cookie3.png' /></div>
    </div>}

    <div className='n2-book-data'>
      <h1 className='n2-book-title'>Garden of Ashes</h1>
      <div className='n2-book-author'>Cookie Mueller</div>

      <div className='n2-book-stats'>
        <div>ISBN 979-8-9893780-3-6</div>
        <div style={{ marginBottom: '6px' }}>120pp | $12.99 USD</div>
        <div style={{ marginTop: '12px' }}>
          <a rel='noreferrer' target='_blank' href='https://asterismbooks.com/product/garden-of-ashes-cookie-mueller'>Buy from Asterism (US/Canada)</a><br />
          <a rel='noreferrer' target='_blank' href='https://publicknowledgebooks.com/products/cookie-mueller-garden-of-ashes-1'>Buy from PKB (UK/EU)</a>
        </div>
      </div>

      <div className='mainText'>
        <p><em>Garden of Ashes</em>, Cookie Mueller's second entry into the Hanuman Books canon(following 1988's <em>Fan Mail, Frank Letters, and Crank Calls</em>) brings together ten stories, autobiographical accounts of her ascent to cult-cinema superstardom, with tales dedicated to several of her fellow Dreamlanders, including "Edith Massey: A Star" and "Divine".'</p>
        <p><strong>Cookie Mueller</strong> (1949–1989) was an American writer, actress, and advice columnist, best remembered as a regular cast member of some of the director John Waters's groundbreaking films, including <em>Multiple Maniacs, Pink Flamingos, Female Trouble</em>, and <em>Desperate Living</em>. She died from AIDS-related complications in 1989.</p>

      </div>
    </div>
  </div>)
}