import React, { useEffect, useState } from 'react';
import CartIcon from '../../icons/cart';
import { Link } from 'react-router-dom';


export default function BookPage() {

  document.title = `On My Painting by Max Beckmann | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Compiled and edited by George Scrivani for Hanuman Books in 1988, On My Painting collects six texts from the pioneering German artist Max Beckmann, who fled Nazi Germany after his paintings—increasingly moody and reflective of the existential terror of the time—were labeled "degenerate".`);


  return (<div className='pageContainer n2-book'>
    <div className='n-book-images'>
      <div className='n-image n-primary-image'><img src='../../beckmann2.png' /></div>
      <div className='n-image n-image-spine'><img src='../../beckmannspine.png' /></div>
      <div className='n-image n-image-back'><img src='../../SeriesOneBack.png' /></div>
      <div className='n-image n-image-2'><img src='../../beckmann3.png' /></div>
      <div className='n-image n-image-3'><img src='../../beckmann4.png' /></div>
    </div>

    <div className='n2-book-data'>
      <div className='n2-book-series'><Link to='/series/one'>Series One</Link> | Autumn 2023</div>
      <h1 className='n2-book-title'>On My Painting</h1>
      <div className='n2-book-author'>Max Beckmann</div>

      <div className='n2-book-stats'>
        <div>ISBN 979-8-9893780-0-5</div>
        <div style={{ marginBottom: '6px' }}>128pp | $12.99 USD</div>
        <div style={{ marginTop: '12px' }}>
          <a rel='noreferrer' target='_blank' href='https://asterismbooks.com/product/on-my-painting-max-beckmann'>Buy from Asterism (US/Canada)</a><br />
          <a rel='noreferrer' target='_blank' href='https://publicknowledgebooks.com/products/max-beckmann-on-my-painting-1'>Buy from PKB (UK/EU)</a>
        </div>
      </div>
      <div className='mainText'>

        <p><em>"One of my problems is to find the Self, which has only one form and is immortal­—to find it in animals and men, in the heaven and in the hell which together form the world in which we live."</em></p>
        <p>Compiled and edited by George Scrivani for Hanuman Books in 1988, <em>On My Painting</em> collects six texts from the pioneering German artist Max Beckmann, who fled Nazi Germany after his paintings—increasingly moody and reflective of the existential terror of the time—were labeled "degenerate". In addition to the titular essay, this volume contains short pieces "Creative Credo" and "The New Program", extracts from his <em>Diaries</em>, three "Letters to a Woman Painter", and the text of a speech given to the philosophy faculty at Washington University in St. Louis, shortly before his death in 1950.</p>
        <p><strong>Max Beckmann</strong> (1884-1950) was a German artist whose body of work includes painting and sculpture. A rising star during the Weimar era, Beckmann left Germany following the ascent of Adolf Hitler, living in self-imposed exile in Amsterdam before finally being granted a teaching position in the United States in 1947. He died of a heart attack in 1950. Though he eschewed the label of "Expressionism", he is often associated with the movement, and was heavily influential on the American offshoots that followed.</p>

      </div>
    </div>
  </div>)
}