import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TextControl } from '../InputControls';
import CartIcon from '../../icons/cart';
import { makeRequest, validateEmail, updateCartState } from '../../utils';
import { useRecoilState } from 'recoil';
import { cartState } from '../../atoms';

export default function AboutPage() {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [cart, setCart] = useRecoilState(cartState);

  document.title = `Series One | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `First series includes books by John Ashbery, Cookie Mueller, Eileen Myles, René Daumal, Max Beckmann, and Dodie Bellamy`);

  const addToCart = (item) => {
    let newCart = updateCartState(cart, item);
    setCart(newCart);
  }

  return (
    <div className='pageContainer booksLanding'>

      <div className='booksPage'>
        <p>Hanuman Editions is a publishing project reimagining the legacy of Hanuman Books, the legendary and cult series of chapbooks that were printed in southern India and published out of the storied Chelsea Hotel in New York City between 1986 and 1993. Our books are designed as a contemporary homage to the playful kitsch and small, object-like format of the original series, evoking the Hanuman Chalisa, a folk compendium of chants to the Hindu god Hanuman, sold in the bazaars of India. As Hanuman Editions, we seek to gather a trans-geographical, trans-disciplinary poetics through commissioned writings in the spirit of avant-garde foundations. <Link to='/about'>Read more on the series here</Link>.</p>

        <h2 className='gold'>Series Two</h2>

        <h3 className='seriesSubhead'>Individual Titles</h3>

        <div className='new-grid'>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/life-story-mckenzie-wark'>
                  <img src='../../LifeStory.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div></div>
              <Link to='/books/life-story-mckenzie-wark'>More</Link>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/grocery-list-bora-chung'>
                  <img src='../../GroceryList.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div></div>              <Link to='/books/grocery-list-bora-chung'>More</Link>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/frontier-index-raymond-pettibon-mike-top'>
                  <img src='../../Frontier.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              {/*<button className='inlineAddToCart' onClick={() => {
                addToCart({
                  id: '9798990416512',
                  priceId: 'price_1PTQPGFoScMjUSrmpXtAGO8I',
                  title: 'The Frontier Index',
                  author: 'Raymond Pettibon & Mike Topp',
                  image: 'Frontier.png',
                  price: 14.99,
                  weight: 6,
                })
              }}><CartIcon /></button>*/}
              <div></div>
              <Link to='/books/frontier-index-raymond-pettibon-mike-top'>More</Link>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/insistence-as-a-fine-art-enrique-vila-matas'>
                  <img src='../../Insistence.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div></div>
              <Link to='/books/insistence-as-a-fine-art-enrique-vila-matas'>More</Link>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/kuruntokai-vivek-narayanan'>
                  <img src='../../Kuruntokai.png' />
                </Link>
              </div>


            </div>
            <div className='new-product-controls'>
              <div></div>
              <Link to='/books/kuruntokai-vivek-narayanan'>More</Link>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/fan-mail-frank-letters-crank-calls-cookie-mueller'>
                  <img src='../../FanMail.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div></div>
              <Link to='/books/fan-mail-frank-letters-crank-calls-cookie-mueller'>More</Link>
            </div>
          </div>
        </div>

        <h3 className='seriesSubhead lessMarginBottom' style={{ marginTop: '64px' }}>Limited Edition Box Sets</h3>
        <p className='seriesGraf gold'>Series Two box sets produced in a hand-numbered edition of 500.</p>

        <div className='boxGrid'>
          <div className='boxGrid-item'>
            <img src='../../series2_fm.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Flamingo</div>

              <Link to='https://asterismbooks.com/product/hanuman-editions-series-two-box-set-flamingo'><CartIcon /></Link>
            </div>
          </div>

          <div className='boxGrid-item'>
            <img src='../../series2_nb.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>New Blue</div>

              <Link to='https://asterismbooks.com/product/hanuman-editions-series-two-box-set-new-blue'><CartIcon /></Link>
            </div>
          </div>

          <div className='boxGrid-item'>
            <img src='../../series2_tb.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Tabriz Blue</div>

              <Link to='https://asterismbooks.com/product/hanuman-editions-series-two-box-set-tabriz-blue'><CartIcon /></Link>
            </div>
          </div>


          <div className='boxGrid-item'>
            <img src='../../series2_ct.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Citrine</div>
              <Link to='https://asterismbooks.com/product/hanuman-editions-series-two-box-set-citrine'><CartIcon /></Link>

            </div>
          </div>
        </div>

        <h2 className='gold'>Series One</h2>

        <h3 className='seriesSubhead'>Individual Titles</h3>


        <div className='new-grid'>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/on-my-painting-max-beckmann'>
                  <img src='../../beckmann2.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div></div>
              <Link to='/books/on-my-painting-max-beckmann'>More</Link>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/the-ice-storm-john-ashbery'>
                  <img src='../../ashbery2.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div></div>
              <Link to='/books/the-ice-storm-john-ashbery'>More</Link>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/feminine-hijinx-dodie-bellamy'>
                  <img src='../../bellamy2.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div></div>
              <Link to='/books/feminine-hijinx-dodie-bellamy'>More</Link>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/garden-of-ashes-cookie-mueller'>
                  <img src='../../mueller.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div></div>
              <Link to='/books/garden-of-ashes-cookie-mueller'>More</Link>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/bread-and-water-eileen-myles'>
                  <img src='../../myles.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div></div>
              <Link to='/books/bread-and-water-eileen-myles'>More</Link>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/the-lie-of-the-truth-rene-daumal'>
                  <img src='../../daumal.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div></div>
              <Link to='/books/the-lie-of-the-truth-rene-daumal'>More</Link>
            </div>
          </div>
        </div>

        <h3 className='seriesSubhead lessMarginBottom' style={{ marginTop: '96px' }}>Limited Edition Box Sets</h3>
        <p className='seriesGraf gold'>Series One box sets produced in a hand-numbered edition of 250.*</p>
        <p className='finePrint'>* Sold out</p>

        <div className='boxGrid'>
          <div className='boxGrid-item'>
            <img src='../../series1_em.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Emerald</div>
            </div>
          </div>
          <div className='boxGrid-item'>
            <img src='../../series1_tq.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Turquoise</div>
            </div>
          </div>
          <div className='boxGrid-item'>
            <img src='../../series1_cp.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Candy Pink</div>
            </div>
          </div>

          <div className='boxGrid-item'>
            <img src='../../series1_az.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Azure Blue</div>
            </div>
          </div>
        </div>


      </div>
    </div >
  )
}