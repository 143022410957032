import React, { useEffect, useState } from 'react';
import CartIcon from '../../icons/cart';
import { Link } from 'react-router-dom';
import './style.css';

export default function BookPage() {

  document.title = `The Kuruntokai and Its Mirror by Vivek Narayanan | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `The influence of <em>The Kuruntokai</em> on Tamil culture and more broadly has been vast but webbed; the poems’ inherent eroticism and expressive range has complicated their history of open dissemination, surfacing most prominently in the English-speaking world through a formally liberal, or “Poundian,” translation by A. K. Ramanujan in the late 1960s, the era that likewise gave birth to the Hanuman Books avant-garde.`);


  return (<div className='pageContainer n2-book'>
    <div className='n-book-images'>
      <div className='n-image n-primary-image'><img src='../../Kuruntokai.png' /></div>
      <div className='n-image n-image-spine'><img src='../../vivekspine.png' /></div>
      <div className='n-image n-image-back'><img src='../../vivekb.png' /></div>
      <div className='n-image n-image-2'><img src='../../vivekp2.png' /></div>
      <div className='n-image n-image-3'><img src='../../vivekp3.png' /></div>
    </div>

    <div className='n2-book-data'>
      <div className='n2-book-series'><Link to='/series/two'>Series Two</Link> | Spring 2024</div>
      <h1 className='n2-book-title'>The Kuruntokai and Its Mirror</h1>
      <div className='n2-book-author'>Vivek Narayanan</div>

      <div className='n2-book-stats'>
        <div>ISBN 979-8-9893780-9-8</div>
        <div style={{ marginBottom: '6px' }}>80pp | $14.99 USD</div>

        <div style={{ marginTop: '12px' }}>
          <a rel='noreferrer' target='_blank' href='https://asterismbooks.com/product/the-kuruntokai-and-its-mirror-vivek-narayanan'>Buy from Asterism (US/Canada)</a><br />
          <a rel='noreferrer' target='_blank' href='https://publicknowledgebooks.com/products/vivek-narayanan-the-kuruntokai-and-its-mirror'>Buy from PKB (UK/EU)</a>
        </div>
      </div>
      <div className='mainText'>

        <p>Vivek Narayanan’s <em>The Kuruntokai and Its Mirror</em> is a fractalized translation and reinterpretation of a classical work in the Tamil Sangam canon, an anthology of 401 short poems composed between 100 CE and 300 CE. As Narayanan writes, the poems “explore in excruciating detail the relationship between the interior and the exterior, the personal and the public” to reflect on the fracture of poetry itself.</p>
        <p>The influence of <em>The Kuruntokai</em> on Tamil culture and more broadly has been vast but webbed; the poems’ inherent eroticism and expressive range has complicated their history of open dissemination, surfacing most prominently in the English-speaking world through a formally liberal, or “Poundian,” translation by A. K. Ramanujan in the late 1960s, the era that likewise gave birth to the Hanuman Books avant-garde. For our contemporary moment, Narayanan provides a close experience and elaboration of <em>The Kuruntokai</em>. His bilingual edition, with text in Tamil and in English, highlights the book’s obliquities, its rich tapestry of internal rhyme in the “dance of the <em>akaval</em> metre,” its poems that “wink” mysteriously to themselves. Raw and fresh, minimalistic and intensive, Narayanan’s <em>The Kuruntokai and Its Mirror</em> romances the ancient amid the exigencies of modern life.</p>

        <p><strong>Vivek Narayanan</strong> was born in India and raised in Zambia. Narayanan’s books of poems include <em>Universal Beach</em> (Harbour Line Press, 2006/In Girum Books, 2011), <em>Life and Times of Mr S</em> (HarperCollins India, 2012), and <em>After</em> (New York Review of Books, 2022). He was the co-editor of <em>Almost Island</em>, an India-based literary journal, from 2007 to 2019. His essays, criticism, and poetry have appeared widely in publications such as <em>Granta</em>, <em>The Paris Review</em>, and <em>Caravan</em>. He teaches in the MFA Creative Writing Program at George Mason University.</p>
      </div>
    </div>
  </div>)
}