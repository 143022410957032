import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TextControl } from '../InputControls';
import { makeRequest, validateEmail } from '../../utils';

export default function DesignPage() {
  const [color, setColor] = useState('#eeae13');

  const colors = ['#eeae13', '#cf8ec8', '#0091aa', '#e77966', '#026f52', '#bf3538', '#6da4d1'];

  document.title = `Subscriptions | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Hanuman Editions offers subscription models that encompass all of our titles and box sets.`);

  useEffect(() => {
    let current = colors.indexOf(color);
    let next = current === colors.length - 1 ? 0 : current + 1;
    setTimeout(() => {
      setColor(colors[next]);
    }, 4000)
  }, [color])

  return (
    <div className='pageContainer'>
      <div className='mainText'>
        <h1>Subscriptions</h1>

        <div className='subImageContainer'>
          <div className='subImage'>
            <img src='../../subscribe.png'></img>
            <div className='subBg' style={{ background: color }}></div>
          </div>
        </div>
        <p>In response to the tremendous wave of patronage, we now offer subscriptions to invite our community into the process of the Hanuman Editions project. Hanuman Editions publishes two series of six books each year, in spring and in fall, with additional initiatives throughout. Books are issued in limited quantities; subscribing is the best way to make sure you don’t miss out on any of our titles and to follow Hanuman’s activities. </p>

        <h3>Individual Books Subscription</h3>

        <p>Features a standard issue* of each title in our biannual Hanuman Editions series, for a total of twelve books per year.</p>
        <p className='finePrint'>*Not including special editions, box sets, or other non-series titles.</p>

        <a className='productBuy' href='https://buy.stripe.com/bIY8xt6i97wEfe0000' target='_blank'>$80 / twice per year</a>

        <h3>Special Edition Box Set Subscription</h3>

        <p>For each biannual series of Hanuman Editions, we produce a limited quantity of hand-numbered box sets. To ensure that you get a set, you can become a sustaining subscriber. You’ll also receive exclusive dispatches from us via email, with behind-the-scenes looks at our design process, production, and supplementary materials, as well as early access to pre-orders for special editions.</p>
        <p>Note: There are a maximum of 200 spots for sustaining subscribers.</p>

        <a className='productBuy' href='https://buy.stripe.com/6oE5lheOF04c4zm145' target='_blank'>$160 / twice per year</a>
      </div>

    </div >
  )
}