import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TextControl } from '../InputControls';
import { makeRequest, validateEmail } from '../../utils';

export default function DistroPage() {

  document.title = `Where to Buy | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `We seek to revive the legacy of Hanuman Books, the legendary and cult series of chapbooks that were printed in southern India and published out of the storied Chelsea Hotel in New York City between 1986 and 1993.`);


  return (
    <div className='pageContainer'>
      <div className='mainText'>

        <h1>Where to Buy</h1>

        <p>Our books ship worldwide, and can be purchased through <a href='https://asterismbooks.com/publisher/hanuman-editions' target='_blank'>Asterism Books</a>, <a href='https://publicknowledgebooks.com/collections/vendors?q=Hanuman%20Editions' target='_blank'>Public Knowledge Books</a>, via a Hanuman <Link to='/subscriptions'>subscription</Link>, or from one of our stockists below. On special request for UK/EU customers and stockists, we can also ship directly from London, where our books are printed. Please email us at <a href='mailto:info@hanumaneditions.com'>info@hanumaneditions.com</a> for more information.</p>


        <p>Our books are currently available to the trade and libraries in the US via <a href='https://asterismbooks.com/publisher/hanuman-editions' target='_blank'>Asterism Books</a> and in the UK via <a href='https://publicknowledgebooks.com/collections/vendors?q=Hanuman%20Editions' target="_blank">Public Knowledge Books</a>. Imminently, we will have similar arrangements with distributors in the UK, Europe, the Indian Subcontinent, and the East.</p>



        <div className='stock-territory bold'>US</div>

        <div className='stock-grid'>
          <div className='stockist'>
            192 Bookshop
            <div className='stock-location'>New York, NY</div>
          </div>

          <div className='stockist'>
            50 Watts Books
            <div className='stock-location'>Philadelphia, PA</div>
          </div>

          <div className='stockist'>
            Aeon Bookstore
            <div className='stock-location'>New York, NY</div>
          </div>

          <div className='stockist'>
            Alienated Majesty books
            <div className='stock-location'>Austin, TX</div>
          </div>

          <div className='stockist'>
            Arundel Books<br />
            <div className='stock-location'>Seattle, WA</div>
          </div>

          <div className='stockist'>
            Atomic Books<br />
            <div className='stock-location'>Baltimore, MD</div></div>

          <div className='stockist'>
            Basket Books & Art<br />
            <div className='stock-location'>Houston, TX</div>
          </div>

          <div className='stockist'>
            Book Beat<br />
            <div className='stock-location'>Oak Park, MI</div>
          </div>

          <div className='stockist'>
            Book Row
            <div className='stock-location'>Brooklyn, NY</div>
          </div>

          <div className='stockist'>
            Brickbat Books
            <div className='stock-location'>Philadelphia, PA</div>
          </div>

          <div className='stockist'>
            Browser Books
            <div className='stock-location'>San Francisco, CA</div>
          </div>

          <div className='stockist'>
            Burn All Books
            <div className='stock-location'>San Diego, CA</div>
          </div >

          <div className='stockist'>
            Codex
            <div className='stock-location'>New York, NY</div>
          </div >

          <div className='stockist'>
            Conveyor Belt Books
            <div className='stock-location'>Convington, KY</div>
          </div >

          <div className='stockist'>
            Deep Vellum Books
            <div className='stock-location'>Dallas, TX</div>
          </div >

          <div className='stockist'>
            Dog Eared Books
            <div className='stock-location'> San Francisco, CA</div >
          </div >

          <div className='stockist'>
            Downbound Books
            <div className='stock-location'>Cincinnati, OH</div >
          </div >

          <div className='stockist'>
            Dream Palace Books
            <div className='stock-location'>Indianapolis, IN</div >
          </div >

          <div className='stockist'>
            East Bay Booksellers
            <div className='stock-location'>Oakland, CA</div >
          </div >

          <div className='stockist'>
            Elliott Bay Book Company
            <div className='stock-location'>Seattle, WA</div >
          </div >

          <div className='stockist'>
            Ergot Records
            <div className='stock-location'>New York, NY</div >
          </div >

          <div className='stockist'>
            Et Al. Books
            <div className='stock-location'>San Francisco, CA</div >
          </div >

          <div className='stockist'>
            Fabulosa Books
            <div className='stock-location'>San Francisco, CA</div >
          </div >

          <div className='stockist'>
            Frenchtown Bookshop
            <div className='stock-location'>Frenchtown, NJ</div >
          </div >

          <div className='stockist'>
            Green Apple Books
            <div className='stock-location'>San Francisco, CA</div >
          </div >

          <div className='stockist'>
            Hi Books
            <div className='stock-location'>Portland, OR</div >
          </div >

          <div className='stockist'>
            Iris Imprint
            <div className='stock-location'>West Orange, NJ</div >
          </div >

          <div className='stockist'>
            Lot 49 Books
            <div className='stock-location'>Philadelphia, PA</div >
          </div >

          <div className='stockist'>
            Mast Books
            <div className='stock-location'>New York, NY</div >
          </div >

          <div className='stockist'>
            McNally Jackson
            <div className='stock-location'>New York/Brooklyn, NY</div >
          </div >

          <div className='stockist'>
            Molasses Books
            <div className='stock-location'>Brooklyn, NY</div >
          </div >

          <div className='stockist'>
            North Figueroa Bookshop
            <div className='stock-location'>Los Angeles, CA</div >
          </div >

          <div className='stockist'>
            Other Books
            <div className='stock-location'>Los Angeles, CA</div >
          </div >

          <div className='stockist'>
            Perelandra Bookshop
            <div className='stock-location'>Fort Collins, CO</div >
          </div >

          <div className='stockist'>
            Pilsen Community Books
            <div className='stock-location'>Chicago, IL</div >
          </div >

          <div className='stockist'>
            Powell's Books
            <div className='stock-location'>Portland, OR</div >
          </div >

          <div className='stockist'>
            Printed Matter
            <div className='stock-location'>New York, NY</div >
          </div >

          <div className='stockist'>
            Raven Bookstore
            <div className='stock-location'>Lawrence, KS</div >
          </div >

          <div className='stockist'>
            R&D Store @ Mass Moca
            <div className='stock-location'>North Adams, MA</div >
          </div >

          <div className='stockist'>
            Roundabout Books
            <div className='stock-location'>Bend, OR</div >
          </div>

          <div className='stockist'>
            Seminary Co-Op
            <div className='stock-location'>Chicago, IL</div >
          </div >

          <div className='stockist'>
            Skylight Books
            <div className='stock-location'>Los Angeles, CA</div >
          </div >

          <div className='stockist'>
            Stories Books & Cafe
            <div className='stock-location'>Los Angeles, CA</div >
          </div >

          <div className='stockist'>
            The Beautiful Idea
            <div className='stock-location'>Charlottesville, VA</div >
          </div >

          <div className='stockist'>
            The Center for Fiction
            <div className='stock-location'>Brooklyn, NY</div >
          </div >

          <div className='stockist'>
            The Strand
            <div className='stock-location'>New York, NY</div >
          </div >

          <div className='stockist'>
            The Word Is Change
            <div className='stock-location'>Brooklyn, NY</div >
          </div >

          <div className='stockist'>
            Topos Bookstore
            <div className='stock-location'>Queens, NY</div >
          </div >

          <div className='stockist'>
            Ulises
            <div className='stock-location'>Philadelphia, PA</div>
          </div>

          <div className='stockist'>
            Unnameable Books
            <div className='stock-location'>Brooklyn</div >
          </div >

          <div className='stockist'>
            Water Row Books
            <div className='stock-location'>Marlborough, MA</div >
          </div >


        </div>

        <div className='stock-territory bold'>UK</div>

        <div className='stock-grid'>
          <div className='stockist'>
            Burley Fisher Books
            <div className='stock-location'>London</div>
          </div>

          <div className='stockist'>
            Burning House Books
            <div className='stock-location'>Glasgow</div>
          </div>

          <div className='stockist'>
            Donlon Books
            <div className='stock-location'>London</div>
          </div>

          <div className='stockist'>
            L&R Bookshop
            <div className='stock-location'>London</div>
          </div>

          <div className='stockist'>
            London Review Bookshop
            <div className='stock-location'>London</div>
          </div>

          <div className='stockist'>
            Good Press
            <div className='stock-location'>Glasgow</div>
          </div>

          <div className='stockist'>
            Midian Books
            <div className='stock-location'>Swadlincote</div>
          </div>

          <div className='stockist'>
            Tate Modern Bookstore
            <div className='stock-location'> London</div>
          </div>

        </div>

        <div className='stock-territory bold'>Europe</div>

        <div className='stock-grid'>
          <div className='stockist'>
            After 8 Books
            <div className='stock-location'>Paris, France</div>
          </div>

          <div className='stockist'>
            Almost Corner Bookshop
            <div className='stock-location'>Rome, Italy</div>
          </div>

          <div className='stockist'>
            Saint-Martin Bookshop
            <div className='stock-location'> Brussels, Belgium</div>
          </div>

          <div className='stockist'>
            Norma T
            <div className='stock-location'>Oslo, Norway</div>
          </div>

          <div className='stockist'>
            Sasori Books
            <div className='stock-location'>Turin, Italy</div>
          </div>
        </div>



        <div className='stock-territory bold'>Canada</div>

        <div className='stock-grid'>
          <div className='stockist'>
            Midnight Mass Books
            <div className='stock-location'>Toronto, ON</div>
          </div>

          <div className='stockist'>
            The Printed Word
            <div className='stock-location'>Dundas, ON</div>
          </div>

          <div className='stockist'>
            TYPE Books
            <div className='stock-location'>Toronto, ON</div>
          </div>
        </div>

        <div className='stock-territory bold'>Australia</div>

        <div className='stock-grid'>
          <div className='stockist'>
            Metropolis Bookshop
            <div className='stock-location'>Melbourne</div>
          </div>

          <div className='stockist'>
            World Food Books
            <div className='stock-location'>Melbourne</div>
          </div>
          <div className='stockist'>
            Paperback Bookshop
            <div className='stock-location'>Melbourne</div>
          </div>

        </div>

      </div>
    </div >
  )
}